import React from 'react';

import BigMap from '../../components/bigmap';
import paacInit from '../../scripts/paac.js';

let Pittsburgh = () => (
    <BigMap callback={paacInit} id="paacmap" title="Zach Sussman - Frequent Transit - Pittsburgh">
        Pittsburgh, <a href="http://portauthority.org">Port Authority of Allegheny County</a>
    </BigMap>
)

export default Pittsburgh;